<template>
  <div class="lfont">
    <v-row>
      <v-col cols="12" md="2" lg="2">
        <DatePicker
          class="date-time"
          style="width: 100%"
          type="date"
          valueType="format"
          slot="activator"
          :append-to-body="true"
          name="founding_date"
          v-model="date"
          @change="handleEventFilterDate($event)"
        ></DatePicker>
      </v-col> </v-row
    ><br /><br />
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="4"
        v-for="(item, index) in employeWorkSheet"
        :key="index"
      >
        <div
          class="work-sheet-item"
          :style="{
            backgroundColor: bgOthersRequest(item.others_request_change),
            border: bdOthersRequest(item.others_request_change),
          }"
        >
          <div class="sheet-title">
            <img src="@assets/timer-icon.png" alt="" class="icon" />
            <div class="text-h4">{{ item.sheet_name }}</div>
          </div>
          <div class="sheet-content">
            <p class="time">{{ item.start_in }} - {{ item.start_out }}</p>
            <div v-if="item.others_request_change">
              <p>
                <v-icon left> mdi-account-edit </v-icon>
                {{ item.others_request_change.name }}
                {{ item.others_request_change.surname }}
              </p>
              <p class="text-danger">
                <v-icon left> mdi-timer </v-icon>
                {{ item.others_request_change.schedule_name }} ({{
                  item.others_request_change.start_in
                }}
                - {{ item.others_request_change.start_out }})
              </p>
              <v-btn
                tile
                color="yellow"
                class="btn lfont"
                @click="approveRequest(item)"
              >
                ອະນຸມັດ
              </v-btn>
              <v-btn
                tile
                color="red"
                class="btn lfont"
                @click="rejectRequest(item.others_request_change)"
              >
                ປະຕິເສດ
              </v-btn>
            </div>
            <div v-else>
              <div
                v-if="
                  item.my_request_change &&
                  item.my_request_change.status != 'reject' &&
                  item.my_request_change.status != 'cancel'
                "
              >
                <p>
                  <v-icon left> mdi-account-edit </v-icon>
                  {{ item.my_request_change.name }}
                  {{ item.my_request_change.surname }}
                </p>
                <p class="text-danger">
                  <v-icon left> mdi-timer </v-icon>
                  {{ item.my_request_change.schedule_name }} ({{
                    item.my_request_change.start_in
                  }}
                  - {{ item.my_request_change.start_out }})
                </p>
                <v-btn
                  tile
                  color="red"
                  class="btn-request lfont"
                  @click="cancelRequest(item.my_request_change)"
                >
                  <v-icon left> mdi-trash-can </v-icon>
                  ຍົກເລີກຂໍປ່ຽນກະ
                </v-btn>
              </div>

              <v-btn
                tile
                color="success"
                class="btn-request lfont"
                @click="
                  requestChangeSchedule(
                    item.schedule_detail_id,
                    item.sheet_name,
                    item.start_in,
                    item.start_out
                  )
                "
                v-else
              >
                <v-icon left> mdi-pencil </v-icon>
                ຂໍປ່ຽນ
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogApprove" persistent max-width="290">
      <v-card>
        <v-card-title class="lfont">
          ຢືນຢັນອະນຸມັດການຂໍປ່ຽນກະວຽກ ?
        </v-card-title>
        <v-card-text class="lfont">
          <p class="text-primary">
            ກະປະຈຸບັນ: {{ approveRequestData.now_schedule.name }} ({{
              approveRequestData.now_schedule.start_in
            }}
            - {{ approveRequestData.now_schedule.start_out }})
          </p>
          <p class="text-danger">
            ປ່ຽນໄປເປັນກະ: {{ approveRequestData.change_schedule.name }} ({{
              approveRequestData.change_schedule.start_in
            }}
            - {{ approveRequestData.change_schedule.start_out }})
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmApproveRequest">
            ຢືນຢັນ
          </v-btn>
          <v-btn color="green darken-1" text @click="dialogApprove = false">
            ຍົກເລີກ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "@/components/Loading";
export default {
  components: {
    DatePicker,
    Loading,
  },
  data() {
    return {
      dialogApprove: false,
      isLoading: false,
      date: new Date().toISOString().slice(0, 10),
      employeWorkSheet: [],
      approveRequestData: {
        now_schedule: {
          name: "",
          start_in: "",
          start_out: "",
        },
        change_schedule: {
          name: "",
          start_in: "",
          start_out: "",
        },
      },
      approveRequestId: "",
    };
  },
  methods: {
    confirmApproveRequest() {
      this.isLoading = true;
      this.dialogApprove = false;
      this.$axios
        .post(`employee/roster/approve/request/change/worksheet`, {
          id: this.approveRequestId,
        })
        .then((res) => {
          this.isLoading = false;
          this.fetchData();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    approveRequest(item) {
      this.approveRequestId = item.others_request_change.id;
      this.dialogApprove = true;
      this.approveRequestData.now_schedule.name = item.sheet_name;
      this.approveRequestData.now_schedule.start_in = item.start_in;
      this.approveRequestData.now_schedule.start_out = item.start_out;

      this.approveRequestData.change_schedule.name =
        item.others_request_change.schedule_name;
      this.approveRequestData.change_schedule.start_in =
        item.others_request_change.start_in;
      this.approveRequestData.change_schedule.start_out =
        item.others_request_change.start_out;
    },
    rejectRequest(item) {
      this.approveRequestId = item.id;
      this.dialogApprove = false;
      this.isLoading = true;
      this.$axios
        .post(`employee/roster/reject/request/change/worksheet`, {
          id: this.approveRequestId,
        })
        .then((res) => {
          this.isLoading = false;
          this.fetchData();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    fetchData() {
      this.isLoading = true;
      let item = {
        date: this.date,
      };
      this.$axios
        .get(`employee/roster`, {
          params: item,
        })
        .then((res) => {
          this.isLoading = false;
          this.employeWorkSheet = res.data.data;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    handleEventFilterDate(event) {
      this.date = event;
      this.fetchData();
    },

    cancelRequest(item) {
      this.isLoading = true;
      this.$axios
        .post(`employee/roster/cancel/request/change/worksheet`, {
          id: item.id,
        })
        .then((res) => {
          this.isLoading = false;
          this.fetchData();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    requestChangeSchedule(
      schedule_detail_id,
      schedule_detail_name,
      start_in,
      start_out
    ) {
      this.$router
        .push({
          name: "employee_roster.request",
          params: {
            date: this.date,
            schedule_detail_id: schedule_detail_id,
            schedule_detail_name: schedule_detail_name,
            start_in: start_in,
            start_out: start_out,
          },
        })
        .catch(() => {});
    },
    bgOthersRequest(request) {
      if (request) return "#97ddfb";
      return "";
    },
    bdOthersRequest(request) {
      if (request) return "2px solid red";
      return "";
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped lang="scss">
.work-sheet-item {
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  .sheet-title {
    text-align: center;
    .icon {
      width: 80px;
      height: 80px;
    }
  }
  .sheet-content {
    .time {
      font-size: 22px;
      font-weight: 800;
    }
    .btn-request {
      color: white;
      font-weight: 800;
    }
    .btn {
      color: black !important;
    }
  }
}
</style>