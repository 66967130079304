var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lfont"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('DatePicker',{staticClass:"date-time",staticStyle:{"width":"100%"},attrs:{"slot":"activator","type":"date","valueType":"format","append-to-body":true,"name":"founding_date"},on:{"change":function($event){return _vm.handleEventFilterDate($event)}},slot:"activator",model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('br'),_c('br'),_c('v-row',_vm._l((_vm.employeWorkSheet),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4","lg":"4"}},[_c('div',{staticClass:"work-sheet-item",style:({
          backgroundColor: _vm.bgOthersRequest(item.others_request_change),
          border: _vm.bdOthersRequest(item.others_request_change),
        })},[_c('div',{staticClass:"sheet-title"},[_c('img',{staticClass:"icon",attrs:{"src":require("@assets/timer-icon.png"),"alt":""}}),_c('div',{staticClass:"text-h4"},[_vm._v(_vm._s(item.sheet_name))])]),_c('div',{staticClass:"sheet-content"},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(item.start_in)+" - "+_vm._s(item.start_out))]),(item.others_request_change)?_c('div',[_c('p',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-edit ")]),_vm._v(" "+_vm._s(item.others_request_change.name)+" "+_vm._s(item.others_request_change.surname)+" ")],1),_c('p',{staticClass:"text-danger"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-timer ")]),_vm._v(" "+_vm._s(item.others_request_change.schedule_name)+" ("+_vm._s(item.others_request_change.start_in)+" - "+_vm._s(item.others_request_change.start_out)+") ")],1),_c('v-btn',{staticClass:"btn lfont",attrs:{"tile":"","color":"yellow"},on:{"click":function($event){return _vm.approveRequest(item)}}},[_vm._v(" ອະນຸມັດ ")]),_c('v-btn',{staticClass:"btn lfont",attrs:{"tile":"","color":"red"},on:{"click":function($event){return _vm.rejectRequest(item.others_request_change)}}},[_vm._v(" ປະຕິເສດ ")])],1):_c('div',[(
                item.my_request_change &&
                item.my_request_change.status != 'reject' &&
                item.my_request_change.status != 'cancel'
              )?_c('div',[_c('p',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-edit ")]),_vm._v(" "+_vm._s(item.my_request_change.name)+" "+_vm._s(item.my_request_change.surname)+" ")],1),_c('p',{staticClass:"text-danger"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-timer ")]),_vm._v(" "+_vm._s(item.my_request_change.schedule_name)+" ("+_vm._s(item.my_request_change.start_in)+" - "+_vm._s(item.my_request_change.start_out)+") ")],1),_c('v-btn',{staticClass:"btn-request lfont",attrs:{"tile":"","color":"red"},on:{"click":function($event){return _vm.cancelRequest(item.my_request_change)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-trash-can ")]),_vm._v(" ຍົກເລີກຂໍປ່ຽນກະ ")],1)],1):_c('v-btn',{staticClass:"btn-request lfont",attrs:{"tile":"","color":"success"},on:{"click":function($event){return _vm.requestChangeSchedule(
                  item.schedule_detail_id,
                  item.sheet_name,
                  item.start_in,
                  item.start_out
                )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" ຂໍປ່ຽນ ")],1)],1)])])])}),1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogApprove),callback:function ($$v) {_vm.dialogApprove=$$v},expression:"dialogApprove"}},[_c('v-card',[_c('v-card-title',{staticClass:"lfont"},[_vm._v(" ຢືນຢັນອະນຸມັດການຂໍປ່ຽນກະວຽກ ? ")]),_c('v-card-text',{staticClass:"lfont"},[_c('p',{staticClass:"text-primary"},[_vm._v(" ກະປະຈຸບັນ: "+_vm._s(_vm.approveRequestData.now_schedule.name)+" ("+_vm._s(_vm.approveRequestData.now_schedule.start_in)+" - "+_vm._s(_vm.approveRequestData.now_schedule.start_out)+") ")]),_c('p',{staticClass:"text-danger"},[_vm._v(" ປ່ຽນໄປເປັນກະ: "+_vm._s(_vm.approveRequestData.change_schedule.name)+" ("+_vm._s(_vm.approveRequestData.change_schedule.start_in)+" - "+_vm._s(_vm.approveRequestData.change_schedule.start_out)+") ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmApproveRequest}},[_vm._v(" ຢືນຢັນ ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogApprove = false}}},[_vm._v(" ຍົກເລີກ ")])],1)],1)],1),(_vm.isLoading)?_c('loading'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }